/*

    sorguAnahtari ve sorguDegeri servise gönderilecek alan ve değeridir.

    nesneMetin ve nesneDeger servisten gelen kayıtların alanlarını temsil eder. nesneMetin select option'u için text değerini, yani gösterilen kısmını
    nesneDeger ise gösterilmeyen value degeri olarak atanır.

    nesne, elde edilen dğerlerin ekleneceği select nesnesinin adıdır.

    dataAlanlariDizisi ise nesne'ye data attribute olarak eklenecek degerlerin alan adlarıdır, e.g, nesneDeger ve nesneMetin gibi...bunlar ek alanlar olarak düşünülebilir...

*/






/*

ekCallback sadece 1 paramtre almalı

*/



window.ajaxSelectYukle = function( payload , url, nesneMetin, nesneDeger, nesne, dataAlanlariDizisi = null, metot = 'GET', ekCallback = null, ekCallbackArgs = null, ekDurumNesnesi = null){

    var CBparametreleri = { nesneMetin          :   nesneMetin,
                            nesneDeger          :   nesneDeger,
                            dataAlanlariDizisi  :   dataAlanlariDizisi,
                            nesne               :   nesne };

    var durumNesnesi;

    if ( ekDurumNesnesi == null ){
        durumNesnesi = $(nesne);
    } else
        durumNesnesi = ekDurumNesnesi;


    ajaxGoturTemel( payload, durumNesnesi , url, metot, ajaxSelectYukleCallback, CBparametreleri, true,  ekCallback, ekCallbackArgs);

}




function ajaxSelectYukleCallback ( ajaxSonuclari, CBparametreleri) {
    
    var data = ajaxSonuclari.data;
    var xhr = ajaxSonuclari.xhr;
    var nesne = CBparametreleri.nesne;
    var nesneMetin = CBparametreleri.nesneMetin;
    var nesneDeger = CBparametreleri.nesneDeger;
    var dataAlanlariDizisi = CBparametreleri.dataAlanlariDizisi;

    if ( xhr.status == 200){

        //selecti temizle, ama boş değerli optionları çıkarma
        // bu optionlar <bu filtreyi kullanma> tarzı meta elemanlardır ve hep listede bulunmalıdır...
        $(nesne + " option:not([value=''])").remove();

        // selecti yükleriz burada...
        $.each(data, function(index, item) {

            yeniGiris = $("<option></option>").text(item[ nesneMetin ] ).val(item[ nesneDeger ] );
            $(nesne).append( yeniGiris );


            //ek alanlar talep edildiysa, onları optionlara data attribute kullanmarak bağdaştır
            if ( dataAlanlariDizisi != null )
               dataAlanlariDizisi.forEach ( function ( extra ){
                                                yeniGiris.data(extra, item[extra] );
                                            });
        });

        // eğer baslangic adinda özel attribute varsa, seçili değeri de ona değiş.
        if ( $(nesne).data('baslangic'))
            $(nesne + ' option[value=' + $(nesne).data('baslangic') + ']').prop('selected', true);

        // değişimi bildir
        $(nesne).trigger('change');
    }

}





















window.ajaxGoturTemel = function ( kaydedilecekNesne , durumNesnesi, url, metot, callback, CBparametreleri, otomatikDurumSakla = true, ekCallback = null, ekCallbackArgs = null, kullaniciGirisi = null, onaylar = null){


    var data = null;

    var durumIkonKullan = false;
    //var durumNesneleri = $('[data-' + dataAlanAdi + '="'+dataAlanDegeri+'"] ' + 'div.' + durumSinif );
    var bypassCallbacks = false;

    // eğer durum iconumuz varsa
    if ( durumNesnesi != null ){
//        durumNesnesi = $(durumNesneleri[0]);
        durumIkonKullan = true;
    }

    //eğer sunucu tarafına bir bilgi gidecekse, eg. onay vs
    if ( kullaniciGirisi != null) {
        kaydedilecekNesne['kullaniciGirisi'] = kullaniciGirisi;
        kaydedilecekNesne['onaylar'] = onaylar;
    }

    // meşgul durumunu göster
    if ( durumIkonKullan ) {
        durumNesnesi.removeClass('errorAnimation');
        durumNesnesi.addClass('busyAnimation');
        //durumNesnesi.show();
    }

    $.ajaxSetup({
      headers: {
        // AJAX call CSRF ile güvenlik için
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
        'Accept' : 'application/json',
       }
    });


    $.ajax({
        type:metot,
        url: url,
        data: kaydedilecekNesne,
        success:function(msg){
            data = msg; },


        complete: function(xhr, textStatus) {
            if ( xhr.status != 0 ){

                if ( xhr.status != 200){                                            // en azından servere bağlandık

                    // özel hata kodlarımız
                    if ( xhr.status == 490)
                        alert ( kullaniciGirisiMesajiBul(xhr) );                           // mesaj kutusu ile hatayı gösterme

                    if ( xhr.status == 491) {
                        var onay = confirm ( kullaniciGirisiMesajiBul(xhr) );              // mesaj kutusu ile onay alıp işlemi onaylı olarakj gerçekleştirme
                        if ( onay ){
                            // durum ikonundan bu iteration sorumlu değil
                            durumIkonKullan = false;
                            //çifte callback çağrısını engellemek için
                            bypassCallbacks = true;
                            // çağrıyı tekrarla.

                            //onay verilen sorunun kodunu bul
                            var onaylananKod = kullaniciGirisiOnayKodunuBul(xhr);

                            //gelen onayları bul
                            var yeniOnaylar = kullaniciGirisiOnaylariBul(xhr);

                            // onay kodunu onaylananlar dzisine ekle
                            if ( onaylananKod != null ){
                                if ( yeniOnaylar == null)
                                    yeniOnaylar = [ onaylananKod];
                                else
                                    yeniOnaylar.push( onaylananKod);
                            }

                            ajaxGoturTemel (kaydedilecekNesne,
                                            durumNesnesi,
                                            url,
                                            metot,
                                            callback,
                                            CBparametreleri,
                                            otomatikDurumSakla,
                                            ekCallback,
                                            ekCallbackArgs,
                                            onay,
                                            yeniOnaylar);}}

                    // hata durumunu göster, HTTP hatası olmalı
                    if ( durumIkonKullan ){
                        durumNesnesi.removeClass('busyAnimation');
                        durumNesnesi.addClass('errorAnimation');
                        durumNesnesi.prop('title', "HTTP " + xhr.status + " " + xhr.statusText + ": \n" + hataMesajiHazirla(xhr) ); }
                } else {
                    // hayat çok güzel, hatasız ajax call http 200 aldık
                    if ( durumIkonKullan ){
                        durumNesnesi.removeClass('busyAnimation');
                        durumNesnesi.removeClass('errorAnimation');
                    }
                    /*
                    if ( durumIkonKullan && otomatikDurumSakla){
                        durumNesnesi.hide(); 
                    }
                    */
                }
            } else {

                // çok garip bir durum var, network veya benzeri hata....
                if ( durumIkonKullan ){
                    durumNesnesi.removeClass('busyAnimation');
                    durumNesnesi.addClass('errorAnimation');
                    durumNesnesi.prop('title', "Nedeni belirlenemeyen bir hata oluştu. Muhtemelen bir ağ hatası."); 
                }
            }


            if ( ! bypassCallbacks ){
                // parametrelere ekleme yapıyoruz
                if ( CBparametreleri != null )
                    CBparametreleri['durumIkonKullan'] = durumIkonKullan;

                if ( callback != null )
                    callback( { data : data , xhr: xhr} , CBparametreleri );

                if ( ekCallback != null )
                    ekCallback ( ekCallbackArgs ); }
        },
    });
}


function kullaniciGirisiOnaylariBul ( xhr) {
    if ( Object.keys(xhr.responseJSON).includes("onaylar") )
        return xhr.responseJSON["onaylar"];
    else
        return null;
}


function kullaniciGirisiOnayKodunuBul ( xhr) {
    if ( Object.keys(xhr.responseJSON).includes("onayKodu") )
        return xhr.responseJSON["onayKodu"];
    else
        return null;
}


function kullaniciGirisiMesajiBul ( xhr) {
    if ( Object.keys(xhr.responseJSON).includes("mesaj") )
        return xhr.responseJSON["mesaj"];
    else
        return null;
}


//ozel alanı henüz kullanmayız.
function hataMesajiHazirla ( xhr , ozelAlan = null) {

    //console.log(xhr);

    if ( xhr.responseJSON == null || xhr.responseJSON == undefined )
        return xhr.responseText;

    var gosterilecekMesaj = "";
    var hataMesajAlanlari = ["message", "hata_aciklama", "mesaj", "aciklama"];

    for ( var hata of Object.keys(xhr.responseJSON) )
        if ( hataMesajAlanlari.includes(hata) )
            if ( xhr.responseJSON[hata] instanceof Object )
                for( let mesaj in xhr.responseJSON[hata] )
                    gosterilecekMesaj += xhr.responseJSON[hata][mesaj] + "\n";
            else
                gosterilecekMesaj += xhr.responseJSON[hata] + "\n";

    return gosterilecekMesaj;

}


