$(document).ready(function(){

    $('.selectListeYonetici.ekle').on('click', ekleTiklandi);
    $('.selectListeYonetici.cikar').on('click', cikarTiklandi);
    $('.selectListeYonetici.tumCikar').on('click', tumMailCikarTiklandi);
    $('.selectListeYonetici.yeniDeger').on('keypress', enterTusKontrolu);
});

/*

1. input girişe, tüm butonlara ve listeye "selectListeYonetici" sınıfını ekle

2. ekleme düğmesine "ekle", seçiliyi çıkarma düğmesine "cikar", listeyi boşalt düğmesine "tumCikar" ve giris alanına da "yeniDeger" classlarini ekle.

3. yeni değerin yer aldığı input'un id'sini kullanarak, tüm düğmelere(ekle,çıkar,temizle) ve inputun kendisine data-tur_id="<id>" attribute eklenir

4. listenin id'sini id="<id>-liste" şekline getir

5. input girişteki tüm data-* attributeler kopyalanacak. data-text ve data-value attributeleri sırasıyla listedeki optionların "görünür text" ve value değerleri olacak

6. input girişe pattern regex'i konulursa, listeye eklenmeden validate olacak ve geçersiz mesajı verilecek.
*/



function girisBul( nesne ) {
    return $('#'+ nesne.data('tur_id') );
}

function listeBul( nesne ) {
    return $( listeSelectorBul(nesne) );
}

function listeSelectorBul( nesne) {
    return "#"+nesne.data('tur_id')+'-liste';
}

function enterTusKontrolu(eventObject) {

    var keycode = (eventObject.keyCode ? eventObject.keyCode : eventObject.which);

    if(keycode == '13'){
        ekle(   girisBul($(this)),
                listeBul($(this)),
                listeSelectorBul($(this)));
        
        eventObject.preventDefault();
    }

}


function ekleTiklandi () {

    ekle(   girisBul($(this)),
            listeBul($(this)),
            listeSelectorBul($(this)));

}


function ekle( yeniDegerNesnesi, nesne, liste_id ){
    

    // eğer yeni sefer saati mısmıl girildiysa ve haftanın bir günü seçildiysa...devam ederik
    if ( yeniDegerNesnesi.val() == null  ||  yeniDegerNesnesi.val() == "" )
        return false;

    if ( ! yeniDegerNesnesi[0].checkValidity() ){
        alert( "Geçersiz bilgi.");
        return false;
    }

    if ( nesne.is("div") )
        var varolanlarDizisi = $(liste_id+' a');
    else if ( nesne.is("select") )
        var varolanlarDizisi = $(liste_id+' option');
    

    //girilen ynei sefer saati. Bunu listeye ekleyceyik
    var yeniDeger = yeniDegerNesnesi.val().replace("-", "");

    //kayıt tekrarını engelle
    var mukerrerKayit = false;
    if ( varolanlarDizisi != undefined )
        for ( eskiDegerNesnesi of varolanlarDizisi ){

            if ( nesne.is("div") )
                var eskiDeger = $(eskiDegerNesnesi).data("deger");
            else if ( nesne.is("select") )
                var eskiDeger = $(eskiDegerNesnesi).val();

            if ( eskiDeger == yeniDeger){
                alert ( "Bu değer daha önce eklenmiş.");
                mukerrerKayit = true;
                break;
            }
        }


    if ( ! mukerrerKayit ){
            if ( nesne.is("div") )
                nesne.append($('<a class="list-group-item list-group-item-action p-1" data-toggle="list" href="#list-home" data-deger="' + yeniDeger + '">'+yeniDeger+'</a>'));
            else if ( nesne.is("select") ){


                var yeniText = yeniValue = yeniDeger;
                var yeniAttrs = {};

                for (const property in yeniDegerNesnesi.data()) {

                    if ( property == "value") { yeniValue = yeniDegerNesnesi.data()[property]; continue;}
                    if ( property == "text") { yeniText = yeniDegerNesnesi.data()[property]; continue; }
                    yeniAttrs["data-"+property] =  yeniDegerNesnesi.data()[property];
                }

                yeniAttrs["value"] = yeniValue;
                yeniAttrs["text"] = yeniText;

                //console.log(yeniAttrs);

                nesne.append($('<option>', yeniAttrs));
            }
    }

    yeniDegerNesnesi.val("");
}




function cikarTiklandi () {

    cikar(  listeBul($(this)),
            listeSelectorBul($(this)));

}
 
function cikar ( nesne, liste_id ) {

    if ( nesne.is("div") )
        $(liste_id + ' a.active').remove();
    else if ( nesne.is("select") )
        $(liste_id + ' option:selected').remove();
}


function tumMailCikarTiklandi () {
    tumMailCikar(  listeBul($(this)),
                    listeSelectorBul($(this)));
}


function tumMailCikar (nesne, liste_id) {

    if ( nesne.is("div") )
        $(liste_id + ' a').remove();
    else if ( nesne.is("select") )
        $(liste_id + ' option').remove();
}
