$(document).ready(function(){
    $('form').on('submit', formGonderiliyor);
    $('.selectYukleyiciTrigger').on('click', formGonderiliyor);
});

function formGonderiliyor (e) {
	$(".selectYukleyici").each(function() {
		var sadeceSecili = $(this).attr("data-sadeceSecili");
		var gonderenAlanAdi = $(this).attr("data-gonderenAlanAdi");

		if ( $(this).attr("form") != null )
			var gonderilecekForm = $("#" + $(this).attr("form") );
		else
			var gonderilecekForm = $(this).closest("form");


		//form içinde da yer almassa parent div'i içine ekle
		if ( gonderilecekForm == null || gonderilecekForm.length == 0 ) {
			gonderilecekForm = $(this).closest("div");
		}


		if ( $(this).is("div") ) {
			if ( sadeceSecili == "true")
				var selector = ".active";
			else
				var selector = "a";
		} else if ( $(this).is("select") ){
			if ( sadeceSecili == "true")
				var selector = ":selected";
			else
				var selector = "option";
		}

		var myObj = [];
		if ( $(this).is("div") )
			$(this).find(selector).each( function() { myObj.push( $(this).data("deger") ); });
		else if ( $(this).is("select") )
			$(this).find(selector).each( function() { myObj.push( $(this).val() ); });

		gonderilecekForm.append( $('<input type="hidden" name="' + gonderenAlanAdi + '">').val( JSON.stringify(myObj)) );
	});
}