$(document).ready(function(){
    $('.grupOlarakKapat').on('click', grupOlarakKapat);

/*
    $('button.tiklayincaKapat').on('click', dugmesiPasiflestirLutfenBekliyoruzYap);
    $('input[type=submit].tiklayincaKapat').on('click', dugmesiPasiflestirLutfenBekliyoruzYap);
    $('input[type=button].tiklayincaKapat').on('click', dugmesiPasiflestirLutfenBekliyoruzYap);

    $('button.tiklayincaKapatLabelDegistirme').on('click', function() {dugmesiPasiflestir( $(this) ); } );
    $('input[type=submit].tiklayincaKapatLabelDegistirme').on('click', function() {dugmesiPasiflestir( $(this) ); });
    $('input[type=button].tiklayincaKapatLabelDegistirme').on('click', function() {dugmesiPasiflestir( $(this) ); });
*/
});



function grupOlarakKapat (e){
    e.preventDefault();

    var nesne = $(this);


    // submit the form manually
    // bir form içinde mi bu düğme?
    //eğer öyleysa bulup submit yapamız lazım çünkü düğme disablec olduğu için kendisi submit yapmaycak...
    if ( nesne.closest("form").length == 1 ){
        var gonderilecekForm = nesne.closest("form").first();
    } else if ( nesne.attr("form") != null && $("#" + nesne.attr("form")).length == 1)
        var gonderilecekForm = $("#" + nesne.attr("form"));


    //sabitle geşiliği ki zıplama olmasın
    const oncekiGenislik = nesne.width();
    const oncekiYukseklik = nesne.height();
    nesne.width( oncekiGenislik );
    nesne.height( oncekiYukseklik);
    nesne.addClass("text-truncate");

    //form kullanılmayan durumlar
    if ( gonderilecekForm == undefined )
        kapatmaIsleminiGerceklestir ( nesne , $(".grupOlarakKapat") ); 
    else {
        //form olan durumlarda submişt eeventi ile işlemleri yparız ki validasyondan takılıyorsa işlemler yapımasın
        gonderilecekForm.on("submit",
                            function() {
                                kapatmaIsleminiGerceklestir ( nesne , $(".grupOlarakKapat") ); 
                            });
        gonderilecekForm.append('<input type="submit" name="' + nesne.attr("name") + '" id="submit_button_which_does_not_exist" value="' +  nesne.attr("value") + '" class="d-none">');
        $("#submit_button_which_does_not_exist").trigger("click");
    }
   

}

function kapatmaIsleminiGerceklestir ( nesne, kapatilacakDigerNesneler ) {
    dugmeYazisiniAyarla ( nesne);
    kapatilacakDigerNesneler.prop('disabled', true);
}


function dugmeYazisiniAyarla ( nesne ) {
    if ( nesne.is("button"))
        //nesne.html("Lütfen bekleyin..");
        nesne.html( "<i class='fas fa-spinner fa-spin'></i>");
    else if ( nesne.is("input") )
        nesne.val("Lütfen bekleyin..");
}


/*

function dugmesiPasiflestir ( nesne = null){

    if ( nesne == null )
        nesne = $(this);

    nesne.prop('disabled', true);

    $(nesne.closest("form")).submit();

}

function dugmesiPasiflestirLutfenBekliyoruzYap (){

	if ( $(this).is("button"))
    	$(this).html("Lütfen bekleyin..");
    else if ( $(this).is("input") )
    	$(this).val("Lütfen bekleyin..");

	dugmesiPasiflestir( $(this) );


}
*/